<template>
  <div>             
    <div class="main_image">
      <img src="@/assets/background.jpg" class="img_fill"/>      
    </div>

    
    <div class="back_bottom">
      <img src="@/assets/back_bottom.svg" style="width : 100%; height : 462px; object-fit: cover;"/>      
    </div>
    
    <!-- <div class="pos_lang">
      <IronSwitch :zCaptions="['KOR','ENG']" @onChange="onChangeLang"></IronSwitch>
    </div> -->
    
    <v-card class="loginInBox pa-6" elevation="15">

      <div class="text-center">
        <div class="d-flex mt-6"  style="margin: 0 auto;  width : 180px;">
          <img src="@/assets/logo.svg" style="width : 45px" />
          <div class="ml-2 text-h2" style="font-weight:bold; color:#004282;" >HIoS</div>
        </div>
        <div class="text-h5" style="color:#004282;" >현대 안전 관리 시스템</div>
      </div>
      
      <v-text-field
        v-model="id"
        label="아이디"
        type="text"
        placeholder="아이디를 입력하세요."
        persistent-placeholder
        color="black"
        v-on:keyup.enter="Setfocus()"      
      ></v-text-field>           

      <v-text-field                      
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"                        
        :type="show1 ? 'text' : 'password'"
        class="mt-n3"
        ref="password"
        v-model="password"
        label="패스워드"                                      
        placeholder="암호를 입력하세요."
        persistent-placeholder
        v-on:keyup.enter="loginFunc()"
        color="black"
        @click:append="how1 = !show1"
      ></v-text-field>
      
      <v-btn class="mt-2 mb-2" x-large color="blue darken-4" dark block @click="loginFunc()">
        <v-icon class="mr-3">lock_open</v-icon>로그인
      </v-btn>

      <div class="d-flex mt-n1">
        <v-btn text style="color:#00428288;" small> 아이디 찾기 </v-btn> <p style="font-size: 10px; margin-top:5px">/</p> <v-btn text style="color:#00428288;" small> 비밀번호 재발급 신청</v-btn>
      </div>

      <v-text-field                      
        append-icon="mdi-send"                                
        class="mt-2"        
        v-model="password2"
        label="인증확인"                                      
        placeholder="로그인 완료 후 인증 가능"
        persistent-placeholder
        v-on:keyup.enter="loginFunc()"
        color="black"
        @click:append="show1 = !show1"
      ></v-text-field>
      <div class="caption" style="color:#00428288;" >
        * 1분 이내에 인증문자가 수신되지 않을시 이곳을 클릭하여 재시도 해주시기 바랍니다.
      </div>

      <div class="caption grey--text mt-2">
        로그인 및 본인인증 완료 후 시스템을 이용하실 수 있습니다. <br>
아이디 / 비밀번호 입력시 반드시 대소문자를 구분하여 입력해 주셔야 합니다.<br>
구글 크롬 브라우저에 최적화 되어 있습니다.<br>
MAP Viewer 프로그램이 자동설치가 되지 않을 경우 설치파일을 다운받아 설치하시기 바랍니다.
      </div>

      <div class="d-flex mt-2"  style="margin: 0 auto;  width : 240px;">
        <v-btn text style="color:#00000088;" class="mr-5"> 이용약관 </v-btn> 
        <v-btn text style="color:#00000088;" > 개인정보처리방침</v-btn>
      </div>
    </v-card>
    
    <MsgBar ref="MsgBar" />     
  </div>
</template>

<script>
  import MsgBar from "@/components/MsgBar.vue";  
  // import IronSwitch from "@/components/IronSwitch.vue";  
  import BasicInfo from "@/BasicInfo.js";
  import axios from "axios"
  import SHA256 from "sha256";    
  import EventBus from '@/eventBus.js';
  import Util from '@/Util.js';
  

  export default {
    components: {            
      MsgBar,            
      // IronSwitch,      
    },
 
    data() {
      return {        
        isKor : true,        
        id: null,
        show1 : false,
        password: null,
        password2: null,
        version : "",      
        cTitle : "",         
      };
    },

    created(){
      this.cSiteId = BasicInfo.SiteId;
      this.cTitle = BasicInfo.Title;
    },

    methods: {     
      onChangeLang(a){
        this.isKor = a;        
        
      },
      Setfocus(){        
        this.$refs.password.focus();
      },
      // test(){
      //   window.open("http://127.0.0.1/phone_popup1.asp", "auth_popup", "width=430,height=640,scrollbar=yes");
      // },

      loginFunc() {
        var posData = {
          UserId : this.id,
          Pwd : SHA256(this.password + "@" + this.id), 
        }                
        axios      
          .post(BasicInfo.UIL_API + "Login", posData , {
              headers: {            
              }
            })
          .then(res => {            
            if (res.data.ResultCd === "00") {               

              this.$store.dispatch("login", res.data) 
              if (res.data.Result1[0].PwdExpireDate != "") {
                if (res.data.Result1[0].PwdExpireDate < Util.sNowDate2()) {
                  EventBus.$emit("onShowMsgBox","비밀번호 변경 요망", "비밀번호 만료일이 지났습니다. 비밀번호를 수정하시기 바랍니다. (" + res.data.Result[0].PwdExpireDate + ")");
                }
              }              
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg); 
              this.$store.dispatch("loginErrorfunc") 
            }
          }).catch(err =>{            
            EventBus.$emit("onShowMsg",true, err);
            this.$store.dispatch("loginErrorfunc")
            
          })              
      }
    },           
  }  
</script>


<style scoped>
  .loginInBox{
    z-index: 3;
    position: fixed;
    top : 100px;
    right : 50px;
    width : 450px;
    height : 650px; 
    /* background: #FF00FF; */
  }
  .pos_lang{
    z-index: 35;
    position: fixed;
    top : 20px;
    right : 50px;
    /* width : 450px;
    height : 650px;  */
    /* background: #FF00FF; */
  }
  .back_bottom{
    z-index: 2;
    position: fixed;        
    width: 100vw;    
    top:calc(100vh - 462px);
    height: 462px; 
    /* background: #FF00FF; */
  }

  .main_image {     
    z-index: 0;
    position: fixed;            
    height : 100%;
    
  } 

  .img_fill{
    height: 110vh;    
    width: 100vw;    
    /* width: 200%; */
    
    object-fit: cover;
  }

</style>